export const appSettings = Object.freeze({
  SERVICE_ERROR: 'serviceError',
  APPLICATION_INTIALIZED: 'APPLICATION_INITIALIZED',
  APP_ID: 'cloudEUWeb'
});

export const appActions = Object.freeze({
  SERVICE_ERROR: 'SERVICE_ERROR',
  SERVICE_OK: 'SERVICE_OK',
  APPLICATION_INITIALIZE: 'APPLICATION_INITIALIZE',
  APPLICATION_INITIALIZE_COMPLETE: 'APPLICATION_INITIALIZE_COMPLETE'
});

export const cookiesConstant = Object.freeze({
  PP_REMEMBER_EMAIL: 'pp_remember_email',
  APPLICATION_TOKEN: 'application_token',
  AUTH_COOKIE: 'auth_cookie'
});

export const CLUSTER_REGION_MAPPING = Object.freeze({
  US: 'US',
  EU: 'EU',
  SG: 'SG',
  AE: 'AE'
});

export const APP_NAMES = Object.freeze({
  quarantine: 'quarantine',
  keyManagement: 'keyManagement',
  landingPage: 'landingPage'
});
